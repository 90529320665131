import {makeStyles} from '@material-ui/core'

const useStyles = makeStyles(({breakpoints, spacing, fontSizes, palette}) => ({
  title: {
    fontSize: fontSizes.title,
    fontWeight: 500,
    fontFamily: 'CamptonMedium',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },
  container: {
    display: 'flex',
    [breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  descriptionContainer: {
    marginLeft: 0,
    paddingLeft: 0,
    maxWidth: 500,
    '& a': {
      color: palette.primary.main,
      textDecoration: 'underline !important',
      fontSizes: fontSizes.description,
      '&:hover': {
        color: `${palette.primary.main} !important`,
      },
    },
  },
  descriptionText: {
    fontFamily: 'CamptonLight',
    marginTop: spacing(2.5),
    fontSize: fontSizes.largeDescriptions,
    fontWeight: 300,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },
}))

export default useStyles
