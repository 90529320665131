import {makeStyles} from '@material-ui/core'

const useStyles = makeStyles(({breakpoints, spacing}) => ({
  centered: {
    display: 'flex',
    margin: spacing(4, 'auto', 0, 'auto'),
    textAlign: 'center',
    [breakpoints.down('sm')]: {
      margin: spacing(0, 'auto'),
    },
  },
  topPadding: {
    paddingTop: spacing(4),
    [breakpoints.down('sm')]: {
      paddingBottom: spacing(7),
    },
  },
}))

export default useStyles
