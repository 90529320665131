import {makeStyles} from '@material-ui/core'

const useStyles = makeStyles(({fontSizes, layout, palette, spacing}) => ({
  tag: {
    textTransform: 'none',
    fontSize: fontSizes.small,
    fontFamily: 'CamptonMedium',
    maxHeight: layout.tags.maxHeight,
    '& div': {
      height: layout.tags.maxHeight,
    },
  },
  clearIcon: {
    backgroundColor: 'white',
    padding: spacing(0.125),
    height: `calc(${layout.tags.maxHeight}px - 4px)`,
    width: `calc(${layout.tags.maxHeight}px - 4px)`,
    borderRadius: '50%',
    marginRight: spacing(0.5),
  },
  department: {
    color: palette.tags.departments.color,
    backgroundColor: palette.tags.departments.backgroundColor,
  },
  industry: {
    color: '#003189',
    backgroundColor: palette.tags.departments.backgroundColor,
  },
  partner: {
    color: palette.tertiary.main,
    backgroundColor: palette.tags.partners.backgroundColor,
  },
  service: {
    color: palette.secondary.main,
    backgroundColor: palette.tags.industries.backgroundColor,
  },
}))

export default useStyles
