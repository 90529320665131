import {createHash} from 'helpers/utils'
import {oneOf, string} from 'prop-types'
import Button from '@material-ui/core/Button'
import classNames from 'classnames'
import ClearRoundedIcon from '@material-ui/icons/ClearRounded'
import React from 'react'
import useStyles from './styles'

const Tag = ({type, text}) => {
  const classes = useStyles()

  return (
    <Button
      className={classNames(classes.tag, classes[type])}
      size="small"
      onClick={() => createHash(`${type}-${text}`)}
    >
      <ClearRoundedIcon className={classes.clearIcon} />
      <div>#{text}</div>
    </Button>
  )
}

Tag.propTypes = {
  text: string.isRequired,
  type: oneOf(['department', 'industry', 'service', 'partner']).isRequired,
}

export default Tag
